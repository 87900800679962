import { useContext } from "react";
import { notification } from "antd";
import { useHotkeys } from "react-hotkeys-hook";
import { AbilityContext } from "../shared/AbilityContext";
import store from "../../store/store";
import { setSearch } from "../../store/actions/setSearch";

const HomeHotKeys = ({ push }: any) => {
  const ability: any = useContext(AbilityContext);

  useHotkeys("shift+q", () => {
    localStorage.setItem("HOME_BI", "false");
    notification.destroy();
    store.dispatch(setSearch({ type: "totalReset", payload: {} }));
    push("/");
  });

  useHotkeys("shift+i", () => {
    if (
      ability.can("ACCESS", "dashcargue") ||
      ability.can("ACCESS", "dashpayments") ||
      ability.can("ACCESS", "dashrates")
    ) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/dashboards");
    }
  });

  // useHotkeys("shift+n", () => {
  //   if (ability.can("ACCESS", "dashboardpayments")) {
  //     localStorage.setItem("HOME_BI", "false");
  //     notification.destroy();
  //     store.dispatch(setSearch({ type: "totalReset", payload: {} }));
  //     push("/home/statuspayments");
  //   }
  // });

  useHotkeys("shift+j", () => {
    if (ability.can("ACCESS", "place")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/clients/places");
    }
  });

  useHotkeys("shift+k", () => {
    if (ability.can("ACCESS", "company")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/companies");
    }
  });

  useHotkeys("shift+c", () => {
    if (ability.can("ACCESS", "account")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/clients/accounts");
    }
  });

  useHotkeys("shift+g", () => {
    if (ability.can("ACCESS", "regional")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/clients/groups");
    }
  });

  useHotkeys("shift+d", () => {
    if (ability.can("ACCESS", "domain")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/miscellaneous/domain");
    }
  });

  useHotkeys("shift+s", () => {
    if (ability.can("ACCESS", "marketer")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/miscellaneous/marketer");
    }
  });

  useHotkeys("shift+4", () => {
    if (ability.can("ACCESS", "rates")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/miscellaneous/rates");
    }
  });

  useHotkeys("shift+m", () => {
    if (ability.can("ACCESS", "payment")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/miscellaneous/payment");
    }
  });

  useHotkeys("shift+t", () => {
    if (ability.can("ACCESS", "transactions")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/cashsourse/transactions");
    }
  });

  useHotkeys("shift+x", () => {
    if (ability.can("ACCESS", "fund")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/cashsourse/fund");
    }
  });

  useHotkeys("shift+o", () => {
    if (ability.can("ACCESS", "cashsource")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/cashsourse/cashsource");
    }
  });

  useHotkeys("shift+r", () => {
    if (ability.can("ACCESS", "budget")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/cashsourse/budget");
    }
  });

  useHotkeys("shift+z", () => {
    if (ability.can("ACCESS", "pending")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/cashsourse/pending");
    }
  });

  // useHotkeys("shift+f", () => {
  //   if (ability.can("ACCESS", "rols")) {
  //     localStorage.setItem("HOME_BI", "false");
  //     notification.destroy();
  //     store.dispatch(setSearch({ type: "totalReset", payload: {} }));
  //     push("/home/users/rols");
  //   }
  // });

  useHotkeys("shift+u", () => {
    if (ability.can("ACCESS", "users")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/users/users");
    }
  });

  useHotkeys("shift+a", () => {
    if (ability.can("ACCESS", "loads")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/assets/loads");
    }
  });

  useHotkeys("shift+w", () => {
    if (ability.can("ACCESS", "meters")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/assets/meters");
    }
  });

  useHotkeys("shift+p", () => {
    if (ability.can("ACCESS", "pagos")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/pay");
    }
  });

  useHotkeys("shift+v", () => {
    if (ability.can("ACCESS", "cargue")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/load");
    }
  });

  useHotkeys("shift+h", () => {
    if (ability.can("ACCESS", "audit")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/audit");
    }
  });

  useHotkeys("shift+y", () => {
    if (ability.can("ACCESS", "descargas")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/downloads");
    }
  });

  useHotkeys("shift+b", () => {
    if (ability.can("ACCESS", "vbots")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/vertebots/vbots");
    }
  });

  useHotkeys("shift+2", () => {
    if (ability.can("ACCESS", "bdownloads")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/vertebots/bdownloads");
    }
  });
  useHotkeys("shift+0", () => {
    if (ability.can("ACCESS", "guruve")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/guruve");
    }
  });

  useHotkeys("shift+l", (event: any) => {
    notification.destroy();
    event.preventDefault();
    let search = document.getElementById("search_bar");
    if (search) {
      search.focus();
    }
  });
  useHotkeys("shift+space", (event: any) => {
    const btn = document.getElementById("btnRefreshCompleted");
    const btnT = document.getElementById("btnRefreshB");
    if (btn) {
      btn.click();
    }
    if (btnT) {
      btnT.click();
    }
  });
  useHotkeys("shift+f", () => {
    if (ability.can("ACCESS", "searchBills")) {
      localStorage.setItem("HOME_BI", "false");
      notification.destroy();
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      push("/home/searchbills");
    }
  });
};
export { HomeHotKeys };
