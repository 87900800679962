import { Image, Result, Typography } from "antd";
import { useEffect, useState } from "react";
import Logo from "../../assets/logo-login.svg";
import { SmartEcoError } from "./SmartEcoError";

const { Title } = Typography;

const InvalidTokenErrorPage = ({ redirect = false, delay = 3 }: any) => {
  const [counter, setCounter] = useState(delay);

  const tick = () => {
    setCounter((counter: any) => counter - 1);
  };

  useEffect(() => {
    if (redirect) {
      const timerID = setInterval(() => tick(), 1000);
      return () => clearInterval(timerID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (counter === 0) {
    window.location.replace("/login");
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundColor: "#ffffff",
        backgroundImage: `url(https://backend-smarteco.${process.env.REACT_APP_URL}/downloads/avatars/fondo.png)`,
      }}
    >
      <Image
        src={Logo}
        preview={false}
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          maxWidth: 150,
          objectFit: "cover",
        }}
      />
      <Result
        icon={<SmartEcoError />}
        title="Error de autenticación"
        subTitle={
          <Title
            level={5}
          >{`Lo sentimos, su autenticación ha vencido, será redireccionado en : ${counter}`}</Title>
        }
      />
    </div>
  );
};

export default InvalidTokenErrorPage;
