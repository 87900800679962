import React from "react";
import { Suspense } from "react";
import { useNavigate } from "./AppRouter";
import Spin from "../components/new-atoms/spin";

const SuspenseLayout: React.FC = ({children}) => {
  const navigate = useNavigate();
  
  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { push: navigate });
    }
    return child;
  });

  return <Suspense fallback={<Spin/>}>
    {childrenWithProps}
  </Suspense>
};

export default SuspenseLayout;