/* eslint-disable import/no-anonymous-default-export */
import { Spin } from "antd";
import "../../styles/Spin.css";
export default () => {
    return (
        <div className="Spin">
            <Spin size="large">
                <div></div>
            </Spin>
        </div>
    );
};