import { Image, Row, Divider } from "antd";
import SideMenu from "../new-atoms/SideMenu";
//import { history } from "../../router/AppRouter";
import { useNavigate } from "../../router/AppRouter";
import Logo from "../../assets/logo-white.svg";

const Sidebar = () => {
  const push = useNavigate();
  return (
    <>
      <Row justify="space-around" align="middle">
        <Image
          preview={false}
          width="80%"
          style={{ cursor: "pointer", padding: 5, marginTop: 10 }}
          onClick={(event) => {
            push("/home");
          }}
          src={Logo}
          alt="Logo"
        />
      </Row>
      <Divider orientation="left" style={{ marginTop: 1, marginBottom: 1 }} />
      <SideMenu />
    </>
  );
};

export default Sidebar;
