import {
  UserOutlined,
  ControlOutlined,
  RollbackOutlined,
  UserSwitchOutlined,
  DatabaseOutlined,
  AppstoreAddOutlined,
  UnlockOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store from "../../store/store";
import {
  getPayload,
  getPermisos,
  organizarSections,
  getPermisosByModule,
} from "../../utils/auth";
import { setSearch } from "../../store/actions/setSearch";
import { Menu } from "antd";
import "../../styles/div-scroll.css";
// import { map } from "lodash";
import ChangeLanguaje from "../../new-hooks/changeLanguaje";
import { setToken, setTokenBack } from "../../utils/auth";
import Hook from "../../new-hooks/companiesData";
import swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "../../router/AppRouter";

const { SubMenu, Item } = Menu;

const SideManu = () => {
  const { t } = useTranslation();
  const payload = getPayload();
  const permisos = getPermisos();
  const permisosSubmenu = organizarSections(permisos);
  const state: any = useSelector((state: any) => state);
  // eslint-disable-next-line
  const [idiom, setIdiom] = useState([
    {
      val: "es",
      idiom: t("spanish"),
    },
    {
      val: "en",
      idiom: t("english"),
    },
    {
      val: "pt",
      idiom: t("Portuguese"),
    },
  ]);

  const reduxState = useSelector((redux: any) => {
    return redux.generalSearch;
  });

  const changeLanguaje = ChangeLanguaje();
  // eslint-disable-next-line
  const data: any = Hook(reduxState);

  const navigate = useNavigate();
  const redirect = (event: any, path: any) => {
    if (event?.domEvent?.ctrlKey || event?.domEvent?.metaKey) {
      window.open(path, "_blank");
      localStorage.setItem("HOME_BI", "false");
    } else {
      localStorage.setItem("HOME_BI", "false");
      store.dispatch(setSearch({ type: "totalReset", payload: {} }));
      navigate(path);
    }
  };
  const history = { location: useLocation() };
  useEffect(() => {
    const payPermisos = getPermisosByModule("pagos", undefined);
    if (
      history.location.pathname === "/home/pay" &&
      payPermisos &&
      !payPermisos.includes("SELECT_MULTIASIGNEE") &&
      !state.generalSearch.asignee
    ) {
      store.dispatch(
        setSearch({
          type: "setFiltersControl",
          payload: {
            ...state.generalSearch,
            asignee: [payload.id],
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload.id, state.generalSearch]);

  const logout = function () {
    localStorage.clear();
    window.location.replace("/login");
  };

  const openLenguaje = async (value: any) => {
    await changeLanguaje({
      languaje: value.key,
    })
      .then(async (res: any) => {
        swal.fire({
          title: `${t("msgIdiom")}`,
          icon: "success",
        });
        setToken(res.data.changeLenguaje.token);
        setTokenBack(res.data.changeLenguaje.back_token);
        window.location.replace("/home");

        return;
      })
      .catch((error: any) => {
        swal.fire({
          title: `${t("error_message1")}`,
          text: error?.message,
          icon: "error",
        });
        return;
      });
  };

  return (
    <>
      <Menu key="menuside" mode="inline" theme="dark">
        <SubMenu
          key="users"
          icon={<UserOutlined style={{ fontSize: 17 }} />}
          style={{
            height: "fit-content",
            maxHeight: 30,
            alignItems: "center",
            justifyContent: "center",
          }}
          title={payload.names}
        >
          <Item
            key="name"
            disabled={true}
            icon={<UserOutlined />}
            onClick={(event: any) => {
              redirect(event, "/home");
            }}
          >
            {payload?.names ?? ""}
          </Item>
          <Item
            key="company"
            disabled={true}
            icon={<DatabaseOutlined />}
            onClick={(event: any) => {
              redirect(event, "/home");
            }}
          >
            {payload?.company_name ?? ""}
          </Item>
          <Item
            key="username"
            disabled={true}
            icon={<UserSwitchOutlined />}
            onClick={(event: any) => {
              redirect(event, "/home");
            }}
          >
            {payload?.nickname ?? ""}
          </Item>
          <Item
            key="rol"
            disabled={true}
            icon={<ControlOutlined />}
            onClick={(event: any) => {
              redirect(event, "/home");
            }}
          >
            {payload?.rol ?? ""}
          </Item>
          <SubMenu
            key="lenguaje"
            title={t("language")}
            style={{
              height: "fit-content",
              maxHeight: 30,
              alignItems: "center",
              justifyContent: "center",
            }}
            icon={
              <>
                <GlobalOutlined />
              </>
            }
          >
            {idiom.map((m) => (
              <Item key={m.val} onClick={openLenguaje}>
                {m.idiom}
              </Item>
            ))}
          </SubMenu>
          <Item
            key="password"
            icon={<UnlockOutlined />}
            onClick={(event: any) => {
              redirect(event, "/home/changepassword");
            }}
          >
            {t("update_password")}
          </Item>
          <Item key="loguot" icon={<RollbackOutlined />} onClick={logout}>
            {t("SignOff")}
          </Item>
        </SubMenu>
      </Menu>
      <Menu key="adminmenu" mode="inline" theme="dark">
        {permisosSubmenu.administration.length ? (
          <SubMenu
            key="admin"
            icon={<AppstoreAddOutlined style={{ fontSize: 17 }} />}
            title={t("management")}
          >
            {permisosSubmenu.administration.map((element: any) => {
              const shortcut =
                element?.shortcut && element.shortcut.length > 0
                  ? `${element.shortcut[0]} + ${element.shortcut[1] ?? ""}`
                  : "";
              return (
                <Item
                  key={element.id.toLowerCase()}
                  style={{ height: "auto" }}
                  onClick={(event: any) => {
                    redirect(event, "/home" + element.path);
                  }}
                  title={`${element.title} ${shortcut ?? ""}`}
                  icon={state.sidebar ? element.short_name : undefined}
                >
                  {state.sidebar ? undefined : element.short_name}
                </Item>
              );
            })}
          </SubMenu>
        ) : null}
      </Menu>
      <Menu key="menupermisos" theme="dark" style={{ overflow: "auto" }}>
        {permisosSubmenu.none.map((element: any) => {
          const shortcut =
            element?.shortcut && element.shortcut.length > 0
              ? `${element.shortcut[0]} + ${element.shortcut[1]}`
              : "";
          return (
            <Item
              key={element.id.toLowerCase()}
              style={{
                height: "fit-content",
                maxHeight: 30,
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={(event: any) => redirect(event, "/home" + element.path)}
              title={`${element.title} ${shortcut ?? undefined}`}
              icon={
                <span
                  style={{ textAlign: "center", fontSize: 17 }}
                  className="material-icons"
                >
                  {element.icon}
                </span>
              }
            >
              {state.sidebar ? undefined : element.short_name}
            </Item>
          );
        })}
      </Menu>
    </>
  );
};
export default SideManu;
