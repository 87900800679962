import React from "react";
import GeneralErrorPage from "./components/pages/GeneralErrorPage";
import InvalidTokenErrorPage from "./components/pages/InvalidTokenErrorPage";
import { InvalidTokenError } from "./utils/InvalidTokenError";
import ReactGA from "react-ga4";

interface ErrorBoundaryState {
  error: object | null;
  errorInfo: object | null;
}
class ErrorBoundary extends React.PureComponent<{}, ErrorBoundaryState> {
  timerID: any;

  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { error: error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.error) {
      if (this.state.error instanceof InvalidTokenError) {
        return <InvalidTokenErrorPage redirect={true} delay={3} />;
      } else {
        ReactGA.gtag('event', 'ERROR', {
          error_message: this.state.error.toString(),
          page_referrer: document.referrer,
        });
        return <GeneralErrorPage message={this.state.error.toString()} />;
      }
    }
    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
