import React from "react";
//import { Suspense } from "react";
import { useNavigate } from "./AppRouter";
//import Spin from "../components/new-atoms/spin";
import ErrorBoundary from "../ErrorBoundary";

const ErrorLayout: React.FC = ({ children }: any) => {
  const navigate = useNavigate();

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { push: navigate });
    }
    return child;
  });

  return <ErrorBoundary>{childrenWithProps}</ErrorBoundary>;
};

export default ErrorLayout;
