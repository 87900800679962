import jwtDecode from "jwt-decode";
import { Outlet, Navigate, history } from "./AppRouter";
import store from "../store/store";
import { setSearch } from "../store/actions/setSearch";
import { FC } from "react";

const setFilters = () => {
  if (history.location.search !== "") {
    const token = jwtDecode(history.location.search);
    store.dispatch(setSearch({ type: "setPaginationControl", payload: token }));
  } else {
    store.dispatch(setSearch({ type: "totalReset" }));
  }
};

const authenticated = () => {
  const token = localStorage.getItem("AUTH_TOKEN_BACK");
  if (token) {
    const decoded: any = jwtDecode(token);
    if (
      decoded &&
      new Date() <= new Date(decoded.exp * 1000) &&
      localStorage.getItem("AUTH_USER_KEY") === decoded?.nickname
    ) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

interface Props {
  redirectPath: string;
  children: any;
}

const ProtectedRoute: FC<Props> = ({ redirectPath = "/login", children }) => {
  if (authenticated()) {
    try {
      setFilters();
    } catch (e) {
      console.error(e);
    }

    return children ? children : <Outlet />;
  }
  return <Navigate to={redirectPath} replace />;
};

export default ProtectedRoute;
