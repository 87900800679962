import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import traslationEn from "./assets/locales/en/translation.json";
import traslationEs from "./assets/locales/es/translation.json";
import translationPt from "./assets/locales/pt/translation.json";
import { getPayload } from "./auth";

let payload = null;

try {
  if (window.location.pathname !== "/credentials") {
    payload = getPayload();
  }
} catch (error: any) {
  console.error(error);
}

let userIdiom: any = "";
let lng: any = "";
let fallbackLng: any = "";

userIdiom =
  !payload ||
  payload?.setting === null ||
  payload?.setting === undefined ||
  payload?.setting === null ||
  payload?.setting === undefined ||
  payload?.setting?.applicationLanguage === null ||
  payload?.setting?.applicationLanguage === undefined
    ? null
    : payload?.setting?.applicationLanguage;

lng =
  userIdiom === null || userIdiom === undefined
    ? navigator.language
    : userIdiom;

fallbackLng = userIdiom === null || userIdiom === undefined ? lng : userIdiom;

const resources = {
  en: {
    translation: traslationEn,
  },
  es: {
    translation: traslationEs,
  },
  pt: {
    translation: translationPt,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: fallbackLng,
  lng: lng,
  keySeparator: false,
  detection: {
    checkWhitelist: true,
  },

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
