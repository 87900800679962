import { Button, Image, Result, Space, Typography } from "antd";
import Logo from "../../assets/logo-login.svg";
import { SmartEcoError } from "./SmartEcoError";

const { Text } = Typography;

const GeneralErrorPage = ({ message = "" }: any) => {
  const redirect = () => {
    window.location.replace(window.location.pathname);
  };
  const backtoHome = () => {
    window.location.replace("/home");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundColor: "#ffffff",
        backgroundImage:
          "url(https://backend-smarteco.vertebra-tech.com/downloads/avatars/fondo.png)",
      }}
    >
      <Image
        src={Logo}
        preview={false}
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          maxWidth: 150,
          objectFit: "cover",
        }}
      />
      <Result
        icon={<SmartEcoError />}
        title="Lo sentimos, ha ocurrido un error."
        extra={
          <Space size="small" align="baseline">
            <Button type="primary" onClick={backtoHome}>
              Inicio
            </Button>
            <Button type="primary" onClick={redirect}>
              Recargar
            </Button>
          </Space>
        }
      />
      <Text type="danger">{message}</Text>
    </div>
  );
};

export default GeneralErrorPage;
