
import { Image } from "antd";
import ErrorImage from "../../assets/Error-02.png";

export const SmartEcoError = () => {
  return <Image
    src={ErrorImage}
    preview={false}
    style={{
      maxWidth: "350px",
    }}
  />;
}